var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex flex-wrap" }, [
    _c(
      "div",
      { staticClass: "lg:w-1/2 md:w-full ml-auto mr-auto mt-12" },
      [
        _c("vx-card", [
          _c("div", { staticClass: "flex flex-wrap justify-center" }, [
            _c("img", {
              staticClass: "mx-auto mb-4",
              attrs: {
                src: require("@/assets/images/pages/not-authorized.png"),
                alt: "graphic-not-authorized",
              },
            }),
          ]),
          _c("div", { staticClass: "flex flex-wrap justify-center" }, [
            _c("h2", [_vm._v("You are not authorize to access this screen")]),
          ]),
          _c(
            "div",
            { staticClass: "flex flex-wrap justify-center" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "mt-4 mr-4",
                  attrs: { type: "border", color: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v("Back to previous page")]
              ),
              _c("vs-button", { staticClass: "mt-4", attrs: { to: "/" } }, [
                _vm._v("Home"),
              ]),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }